export default [
  {
    path: '/call',
    name: 'call',
    component: () => import(/* webpackPrefetch: true */ '@/views/call/Call.vue'),
    meta: {
      resource: 'call',
    },
  },
]
